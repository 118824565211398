<template>
  <step-lesson-layout :title="$t('module3.part2.lesson3.title')">
    <template v-slot:content>
      <div class="grid-wrapper">
        <!-- BLOCK 1 -->
        <div class="lesson-block block1">
          <img :src="require('@/assets/logos/aides/logo-mpr-copro.svg')" :alt="$t('global.imageAlts.logoMprCopro')">
          <br>
          <p class="paragraphe">{{ $t('module3.part2.lesson3.block1.content') }}</p>
        </div>

        <!-- BLOCK 2 -->
        <div class="lesson-block">
          <p class="title">{{ $t('module3.part2.lesson3.block2.title') }}</p>
          <p class="paragraphe">{{ $t('module3.part2.lesson3.block2.content') }}</p>
          <div class="check-list">
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check1')"></app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check2')"></app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check3')"></app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check4')"></app-icon-block>
          </div>
        </div>

        <!-- BLOCK 3 -->
        <div class="lesson-block">
          <p class="title">{{ $t('module3.part2.lesson3.block3.title') }}</p>
          <div class="flex-wrapper montant-wrapper">
            <img :src="require('@/assets/module3/part2/illu-money.svg')" :alt="$t('global.imageAlts.moneyIllu')">
            <div class="montant">
              <p class="label">{{ $t('module3.part2.lesson3.block3.montant') }}</p>
              <p class="annotation">{{ $t('module3.part2.lesson3.block3.annotation') }}</p>
            </div>
          </div>
          <p class="label">{{ $t('module3.part2.lesson3.block3.label') }}</p>
          <p class="paragraphe" v-html="$t('module3.part2.lesson3.block3.content')"></p>
          <p class="paragraphe" v-html="$t('module3.part2.lesson3.block3.content2')"></p>
          <p class="sub-title">{{ $t('module3.part2.lesson3.block3.subTitle') }}</p>
          <p class="paragraphe" v-html="$t('module3.part2.lesson3.block3.content3')"></p>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppIconBlock from '@/components/layout/AppIconBlock'

export default {
  name: 'Module3Part2Lesson3',
  components: { AppIconBlock, StepLessonLayout, AppButtonLayout },
  setup (props, context) {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/module3Lesson";

.montant {
  max-width: 230px;
  .label {
    border-radius: 80px;
    background-color: rgba(#b5c84e, .4);
    padding: $space-s $space-ml;
    text-align: center;
    font-weight: $fw-l;
    color: $c-text-dark;
  }
  .annotation {
    color: $c-text-dark;
    font-size: $fz-xs;
    font-weight: $fw-m;
  }
}

.flex-wrapper {
  &.montant-wrapper {
    justify-content: space-between;
  }
}

@media (min-width: $bp-tablet) {
  .grid-wrapper {
    .lesson-block + .lesson-block {
      margin: 0;
    }
    display: grid;
    gap: $space-s;
    grid-template-columns: repeat(2, 1fr);
    .block1 {
      grid-column-start: span 2;
    }
  }
}
</style>
